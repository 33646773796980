
import { __app, __fecha } from '../../../../../utils/myApp';


import React from 'react';

const PagosDirectos = ({PAGOS}) => {
    return (
        <>
            {PAGOS.map((item, key) => (
                <>
                {
                    item.estado == 1 &&
                    <tr key={key}>
                        <td>{item.id}</td>
                        <td>{ __fecha.formatoFecha({$fecha:item.CREADO}) }</td>
                        <td>{item.nombre_user}</td>
                        <td>{item.forma_pagos_nombre}</td>
                        <td>{item.banco_nombre}</td>
                        <td>{item.estacione_nombre}</td>
                        <td>#{item.turno_id}</td>
                        <td>  <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total :  item.total , color : true , icono : false , numerosPositivos : true  } )} }></span> </td>
                        
                    </tr>
                }
                </>
            ))}

        </>
    );
};

export default PagosDirectos;