
import { __app, __fecha } from '../../../utils/myApp';
import { Numpositivo } from '../../../Core/MatematicasGlobales';
import { useContextLocalValue } from './Core/ContextTercero';


export const TableroCliente = ({infoTercero , mostrarFormAbonoGlobal}) => {

     const [ data ,  dispatch ]  = useContextLocalValue();
    
        const actualizarDatos  = () => {
    
            console.log({...data.info  , actualizarPerfil: __app.numeroFecha() })
    
            dispatch({
                type : 'SET_DATA',
                data : {...data.info  , actualizarPerfil: __app.numeroFecha()  }, 
            })
        }
    return (

        <>
            <h3>Tablero de Cliente  </h3>

            <button onClick={() => console.log(infoTercero)}>Log </button>

            <div class="row g-4">
            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card border border-primary">
                <div class="card-body">
                    <h4 class="card-title">Saldo a Favor por Devoluciones </h4>
                   
                    { ( infoTercero?.Ventapagar && parseFloat(infoTercero.Ventapagar) !== 0 )  && 
                            <>
                                <div className='d-flex   justify-content-between'>
                                    +  <h3>$ {__app.formato_decimal({total : Numpositivo( infoTercero.Ventapagar)  })} </h3>
                                </div>
                            </> 
                        }
                </div>
                </div>
            </div>
           
           
            <div className="col-sm-6 col-md-4 col-lg-3">
            <div className="card border border-secondary">
                <div className="card-body">
                <h4 className="card-title">Saldo a Favor Directo</h4>
               
                <h3>
                    ${" "}
                    {
                    // Verificar si el saldo existe y no es 0, de lo contrario mostrar 0
                    infoTercero?.SaldoAdelantadoVenta && parseFloat(infoTercero.SaldoAdelantadoVenta) !== 0
                        ? __app.formato_decimal({ total: Numpositivo(infoTercero.SaldoAdelantadoVenta) })
                        : "0" // Si no existe o es 0, mostrar 0
                    }
                </h3>


                <button
                    className="btn btn-success"
                    onClick={() =>
                    mostrarFormAbonoGlobal({
                        total: 0,
                        master_id: 1,
                        ct_operacione_id: 1,
                        tipo_credito_id: null,
                        tipo_credito_nombre: null,
                        tipo_de_pago: 2,
                    })
                    }
                >
                    Adelantar Saldo <i className="fa-solid fa-coins"></i>
                </button>
                </div>
            </div>
            </div>




            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card border border-secondary">
                <div class="card-body">
                    <h4 class="card-title">Saldo a Favor por Bonos Regalos </h4>
                    <p class="card-text">$ 50.0000</p>
                </div>
                </div>
            </div>



            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card border border-secondary">
                <div class="card-body">
                    <h4 class="card-title">Saldo a Favor por Plan Separe </h4>
                    <p class="card-text">$ 50.0000</p>
                </div>
                </div>
            </div>

    
            <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="card border border-danger">
                <div class="card-body">
                    <h4 class="card-title">CUENTAS POR COBRAR </h4>
                  

                         {infoTercero?.Ventacobrar && infoTercero.Ventacobrar.length > 0  && infoTercero.Ventacobrar.map((data, index) => 
                                <div className=' bg-gradient bg-opacity-50 px-3 border p-2'>
                                <p>
                                
                               
                                    Tipo de Credito : <span className='bg-info text-bg-info  badge'> {data.nombre_credito} </span>
                                    <button onClick={ () => mostrarFormAbonoGlobal({
                                            total : data.total ,
                                            master_id : 1, 
                                            ct_operacione_id : 1,
                                            tipo_credito_id : data.tipo_credito_id,
                                            tipo_credito_nombre : data.nombre_credito,
                                    }) } className='btn btn-sm btn-principal'> Abonar </button>
                                </p>

                                <div className='d-flex  justify-content-between'>
                                    <h3 className='text-danger'>$ {__app.formato_decimal({total : data.total })} </h3>
                                </div>
                            </div>                     
                        )} 
                </div>
                </div>
            </div>
         
         
            </div>
        </>
    )
    
}



export default TableroCliente

/*
{**

    
     *  TABLERO DE PROVEEDOR
     *        
    { (infoTercero.Comprapagar && parseFloat(infoTercero.Comprapagar) !== 0 )  && 
        <div className='mt-5 bg-warning bg-opacity-50  px-3 bg-gradient p-2'>
            Saldo pendiente por modulo <b> Compras</b>
            <div className='d-flex   justify-content-between'>
                <h3>$ {__app.formato_decimal({total : Numpositivo( infoTercero.Comprapagar)  })} </h3>
                <button onClick={ () => mostrarFormAbonoGlobal({
                    total : Numpositivo(infoTercero.Comprapagar) ,
                    master_id : 2, 
                    ct_operacione_id : 2,
                    tipo_credito_id : 0,
                    tipo_credito_nombre : null,
            }) } className='btn btn-sm btn-warning'> Abonar </button>
            </div>
        </div> 
    }
     
*/