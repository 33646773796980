import React from 'react';
import { __app, __fecha } from '../../../../utils/myApp';
import Pendiente from '../../Componentes/Pendiente';
import { Numpositivo } from '../../../../Core/MatematicasGlobales';
import HistorioDeConsumos from './HelperPagos/HistorioDeConsumos';
import PagosConsumidos from './HelperPagos/PagosConsumidos';
import PagosDirectos from './HelperPagos/PagosDirectos';

const ListaPagos = ({document , setMenu }) => {

    const {   PAGOS , DOCUMENTO ,  PAGOS_CONSUMIDOS , PAGOS_HISTORIAL_DE_CONSUMO } = document; 


 
    const totalDocumento = parseFloat(DOCUMENTO.CtDocumento_SUBTOTAL_3);
    
    const totalPagadoDirecto = PAGOS
    .filter((item) => item.estado == 1) // Seleccionar elementos con estado igual a 1
    .reduce((acumulador, item) => acumulador + parseFloat(item.total), 0); // Sumar el valor de 'total'



    const TotalConsumido = PAGOS_CONSUMIDOS
    .reduce((acumulador, item) => acumulador + parseFloat(item.tt), 0); // Sumar el valor de 'total'


    const TotalHistoricoConsumo = PAGOS_HISTORIAL_DE_CONSUMO
    .reduce((acumulador, item) => acumulador + parseFloat(item.tt), 0); // Sumar el valor de 'total'
    

    //// El total del pago real del coudmento
    const totalPagado = totalPagadoDirecto + TotalConsumido + TotalHistoricoConsumo;


    const pendiente = Numpositivo(totalDocumento) - Numpositivo(totalPagado);

    return (
        <div>
            <div className="d-flex justify-content-between align-items-end mb-4">     
                        <h5 className="text-center" >
                            Informacion de pagos  
                        </h5>

                        <div>

                            {pendiente > 0 && <button onClick={() =>setMenu(2)} className="btn btn-small btn-success me-2">
                                Registrar abono   
                            </button>}
                                
                  
                        </div>
                    </div>
                    
                    <br />
                    
                   <div className='table-responsive'>
                   <table className="table table-sm border-top border-translucent fs-8 mb-0 table-bordered">
                        <tr>
                            <th className="text-center">#Pago</th>
                            <th className="text-center">Fecha</th>
                            <th className="text-right">Responsable</th>
                            <th className="text-right">Forma de Pago</th>
                            <th className="text-right">Banco</th>
                            <th className="text-right">Sede</th>
                            <th className="text-right">Turno</th>
                            <th className="text-center">Total</th>
                     
                        </tr>
                   

                        <tbody>
                                <PagosDirectos PAGOS={PAGOS} />


                                
                                <PagosConsumidos PAGOS_CONSUMIDOS={PAGOS_CONSUMIDOS} />




                                <HistorioDeConsumos PAGOS_HISTORIAL_DE_CONSUMO={PAGOS_HISTORIAL_DE_CONSUMO}/>

                        </tbody>
                        <tfoot>
                          
                            <tr >
                                <td colSpan={8}>. </td>
                            
                            </tr>    
                            <tr >
                                <td colSpan={6}></td>
                                <td>Total Pagado</td>
                                <th>$  <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total  : totalPagadoDirecto , color : true , icono : false , numerosPositivos : true  } )} }></span> </th>
                            </tr>


                            <tr >
                                <td colSpan={6}></td>
                                <td>Consumo de saldos a Favor </td>
                                <th>$  <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total  : TotalConsumido , color : false , icono : false , numerosPositivos : true  } )} }></span> </th>
                            </tr>

                            <tr >
                                <td colSpan={6}></td>
                                <td>Historico de Consumo </td>
                                <th>$  <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total  : TotalHistoricoConsumo , color : false , icono : false , numerosPositivos : true  } )} }></span> </th>
                            </tr>

                            <tr >
                                <td colSpan={6}></td>
                                <td>Saldo Pendiente </td>
                                <th> <Pendiente total={totalDocumento}   abonado={totalPagado} /> </th>
                            </tr>

                        </tfoot>
                    </table>
                   </div>
        </div>
    );
};

export default ListaPagos;